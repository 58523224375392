import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Footer from '@sections/Footer';
import Fortune from '@common/Fortune';
import { Section, Container } from '@components/global';

const NotFoundPage = () => (
  <Layout>
    <Navbar hideMenu={true} />
    <Section>
      <Container>
        <h1>Sorry...Nothing's here. Have a fortune (cookie).</h1>
      </Container>
    </Section>
    <Fortune />
    <Footer />
  </Layout>
);

export default NotFoundPage;
